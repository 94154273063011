.header__container-light{
    border-radius: 15px;
    margin: 1rem;
    transition: all 0.3s ease;
    border-color: #88bde9;
    box-shadow: 0 0 15px #6db0e7;
}
.header__container{
    border-radius: 15px;
    margin: 1rem;
    transition: all 0.3s ease;
    border-color: #7af77a;
    box-shadow: 0 0 15px #4ff701;
}
.header__glow{
    background-color: #0f0f0f;
    top: 'auto';
    bottom: 0;
}
.header__glow-light{
    background-color: white;
    top: 'auto';
    bottom: 0;
    color: #c7c7c7
}

.header__menu{
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 150px;
    height: 100px;
    border: none;
}

.menu-light{
    background-color: white;
    color: #0f0f0f;
}
.menu-dark{
    background-color: #0f0f0f;
    color: white;

}
.link-dark{
    text-decoration: none;
    color: white;
} 
.link-light{
    text-decoration: none;
    color: #0f0f0f;
}

.nav-link {
    position: relative;
    overflow: hidden;
}

.nav-link:hover {
    transform: translateX(5px);
}

/* Light mode hover effect */
.container-light .nav-link:hover {
    background-color: rgba(33, 150, 243, 0.1) !important;
}

/* Dark mode hover effect */
.container-dark .nav-link:hover {
    background-color: rgba(76, 175, 80, 0.1) !important;
}

.nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

/* Light mode gradient */
.container-light .nav-link::after {
    background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
}

/* Dark mode gradient */
.container-dark .nav-link::after {
    background: linear-gradient(45deg, #4CAF50 30%, #45D1A1 90%);
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.header__title-light {
    background: linear-gradient(120deg, #00C6FF, #0072FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header__title-dark {
    background: linear-gradient(120deg, #4CAF50, #45D1A1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.icon-animation {
    transition: transform 0.3s ease;
}

.icon-animation:hover {
    transform: scale(1.1);
}

.isOpen .icon-animation {
    transform: rotate(180deg);
}

.MuiPopover-paper{
    background-color: transparent !important;
    background-image: none !important;
    padding: 1rem;
}