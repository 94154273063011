.App {
    text-align: center;
    width: 100vw;
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: calc(64px - 2rem);
    animation: slideinFromBottom 1.5s ease-out;
}

@keyframes slideinFromBottom {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.App-header-dark {
    background-color: #0f0f0f;
    background-image: radial-gradient(circle at 50% 50%, #1a1a1a 0%, #0f0f0f 100%);
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
    color: white;
    padding-top: 64px;
}

.App-header-light {
    background-color: #ffffff;
    background-image: radial-gradient(circle at 50% 50%, #ffffff 0%, #f5f5f5 100%);
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
    color: #0f0f0f;
    padding-top: 64px;
}

.landing__content {
    display: flex;
    padding-top: 64px;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    padding: 0 2rem;
}

.landing__title {
    font-size: 3rem;
    font-weight: 700;
    margin: 2rem 0;
    background: linear-gradient(120deg, #00C6FF, #0072FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeInUp 1s ease-out;
}

.App-header-dark .landing__title {
    background: linear-gradient(120deg, #4CAF50, #45D1A1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.landing__textBlock {
    text-align: center;
    margin: 2rem 0;
}

.landing__text {
    margin: 1.5rem 0;
    line-height: 1.6;
    font-size: 1.2rem;
}

.App-link {
    color: #86ff4e;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
}

.App-link.light {
    color: #2196F3;
}

.hover-effect::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.hover-effect:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.landing__social-links {
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
}

.social-icon {
    color: #86ff4e;
    font-size: 2rem;
    transition: all 0.3s ease;
    padding: 0.5rem;
    border-radius: 50%;
}

.social-icon.light {
    color: #2196F3;
}

.social-icon:hover {
    transform: translateY(-5px);
    background: rgba(33, 150, 243, 0.1);
}

.landing__lang-btns{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    animation-duration: 9s;
    animation-name: slideinFromBottom;
}

.landing__darkMode-btn{
    background-color: #86ff4e !important;

}

.landing__darkMode-btn-light{
    background-color: rgb(0, 174, 255) !important;
}

.darkmode__icon{
    height: 30px;
    width: 30px;
}

.landing__btn{
    cursor: pointer;
}

.landing__btn:hover{
    transform: scale(1.2,1.2);
}

.landing__btn:active{
    transform: scale(1.5,1.5);
}

.landing__darkMode-btn:active{
    transform: scale(1.5,1.5);
}

.landing__darkMode-btn-light:active{
    transform: scale(1.5,1.5);
}

@media (max-width: 768px) {
    .App-logo {
        height: 30vmin;
        margin-top: calc(64px - 2rem);
    }

    .landing__title {
        font-size: 2rem;
    }

    .landing__text {
        font-size: 1rem;
    }

    .landing__social-links {
        gap: 1.5rem;
    }
}