ul {
    list-style-type: none;
}

.projects__container {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 64px);
    padding-top: 124px;
    box-sizing: border-box;
}

.projects__title {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 2rem 0;
    background: linear-gradient(120deg, #00C6FF, #0072FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Dark mode gradient for titles */
.container-dark .projects__title {
    background: linear-gradient(120deg, #4CAF50, #45D1A1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}