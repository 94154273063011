.about__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

.container-dark {
    background-color: #0f0f0f;
    color: white;
}

.container-light {
    background-color: white;
    color: #0f0f0f;
}

.about__lang-btns {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-bottom: -12px !important;
}

.about__btn {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.about__content {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.about__title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    letter-spacing: -0.02em;
}

.gradient-text {
    background: linear-gradient(120deg, #00C6FF, #0072FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container-dark .gradient-text {
    background: linear-gradient(120deg, #4CAF50, #45D1A1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about__text-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box;
}

.about__text {
    font-size: 1.2rem;
    line-height: 1.8;
    text-align: justify;
    opacity: 0.9;
    width: 100%;
    word-wrap: break-word;
    text-justify: inter-word;
    box-sizing: border-box;
}

.about__intro {
    font-size: 1.4rem;
    font-weight: 500;
}

.about__content-text {
    font-weight: 400;
}

@media (max-width: 768px) {
    .about__container {
        padding: calc(64px + 2rem) 1rem 2rem 1rem;
    }

    .about__content {
        width: 100%;
    }

    .about__title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .about__text {
        font-size: 1rem;
        line-height: 1.6;
        text-align: justify;
        text-justify: inter-word;
    }

    .about__intro {
        font-size: 1.1rem;
    }

    .about__lang-btns {
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .about__container {
        padding: 3rem 1rem;
    }

    .about__title {
        font-size: 2rem;
    }

    .about__text {
        font-size: 1rem;
    }

    .about__intro {
        font-size: 1.1rem;
    }
}